// THIS FILE IS AUTO-GENERATED. You may edit by hand, but do so with care.
//   - alphabetize all types & fields
//   - make proper use of union types for enumerated values
//   - do not create "extra" interfaces, enums, etc that don't exist in Praxis API DSL
//
// TO GENERATE THIS FILE ON YOUR LAPTOP when you have changed service FOO:
//   1) cd backend/FOO
//   2) bundle exec rake praxis:docs:generate
//   3) cd frontend/@im-frontend/client
//   4) yarn run codegen:FOO
//   5) commit the resulting changes

import { Client, Response } from '@im-frontend/praxis-api/interfaces';
import { stringify } from '@im-frontend/praxis-api/qs';
import * as schemas from './schemas';

export class Endpoints<R extends Response> {
  client: Client<R>;
  constructor(client: Client<R>) {
    this.client = client;
  }

  // AccreditationVerifications
  accreditationVerifications = {
    // get /accreditation_verifications/{id}
    show: (params: { id: number; fields?: string }) => {
      return this.client.get<schemas.AccreditationVerification>(
        `/accreditation_verifications/${params.id}?${stringify(params)}`
      );
    },

    // patch /accreditation_verifications/{id}/actions/investor_verified
    investorVerified: (params: { id: number }) => {
      return this.client.patch(
        `/accreditation_verifications/${params.id}/actions/investor_verified`
      );
    },

    // patch /accreditation_verifications/{id}/actions/save_for_later
    saveForLater: (params: { id: number }) => {
      return this.client.patch(
        `/accreditation_verifications/${params.id}/actions/save_for_later`
      );
    },

    // patch /accreditation_verifications/{id}/actions/track_parallel_flow_started
    trackParallelFlowStarted: (
      params: { id: number },
      payload: {
        analyticsData?: string;
      }
    ) => {
      return this.client.patch(
        `/accreditation_verifications/${params.id}/actions/track_parallel_flow_started`,
        payload
      );
    },
  };

  // Aggregations
  aggregations = {
    // get /aggregations/distributions
    distributions: () => {
      return this.client.get<schemas.AggregationDistribution[]>(
        `/aggregations/distributions`
      );
    },
  };

  // AmLeaseExpirations
  amLeaseExpirations = {
    // get /am_lease_expirations/actions/latest
    latest: (params: { fields?: string; filters?: string } = {}) => {
      return this.client.get<schemas.AmLeaseExpiration[]>(
        `/am_lease_expirations/actions/latest?${stringify(params)}`
      );
    },
  };

  // AmMetrics
  amMetrics = {
    // get /am_metrics/actions/latest
    latest: (params: { fields?: string; filters?: string } = {}) => {
      return this.client.get<schemas.AmMetric[]>(
        `/am_metrics/actions/latest?${stringify(params)}`
      );
    },

    // post /am_metrics/actions/latest_computed_metric
    latestComputedMetric: (payload: {
      assetIds: number[];
      name: 'DSCR' | 'Debt Yield' | 'LTV';
      periodEndsOn: Date;
      periodStartsOn: Date;
    }) => {
      return this.client.post<schemas.AmLatestComputedMetric[]>(
        `/am_metrics/actions/latest_computed_metric`,
        payload
      );
    },
  };

  // AmReports
  amReports = {
    // get /am_reports
    index: (params: { fields?: string; filters?: string } = {}) => {
      return this.client.get<schemas.AmReport[]>(
        `/am_reports?${stringify(params)}`
      );
    },
  };

  // AmSettings
  amSettings = {
    // get /am_settings/instance
    instance: (params: { fields?: string } = {}) => {
      return this.client.get<schemas.AmSetting>(
        `/am_settings/instance?${stringify(params)}`
      );
    },
  };

  // AnalyticsEvents
  analyticsEvents = {
    // post /analytics_events
    create: (payload: { data?: string; type?: string }) => {
      return this.client.post(`/analytics_events`, payload);
    },
  };

  // Assets
  assets = {
    // get /assets
    index: (params: { fields?: string } = {}) => {
      return this.client.get<schemas.Asset[]>(`/assets?${stringify(params)}`);
    },

    // get /assets/transitive_assets
    transitiveAssets: (params: { fields?: string } = {}) => {
      return this.client.get<schemas.Asset[]>(
        `/assets/transitive_assets?${stringify(params)}`
      );
    },
  };

  // AuthenticatedFirm
  authenticatedFirm = {
    // get /authenticated_firm
    info: (params: { fields?: string; view?: string } = {}) => {
      return this.client.get<schemas.AuthenticatedFirmInfo>(
        `/authenticated_firm?${stringify(params)}`
      );
    },
  };

  // BankAccounts
  bankAccounts = {
    // get /bank_accounts
    index: (params: { fields?: string } = {}) => {
      return this.client.get<schemas.BankAccount[]>(
        `/bank_accounts?${stringify(params)}`
      );
    },

    // post /bank_accounts
    create: (payload: {
      accountNumber?: string;
      accountType?:
        | 'personal_checking'
        | 'personal_savings'
        | 'commercial_checking'
        | 'commercial_savings';
      bankName?: string;
      nickname?: string;
      routingNumber?: string;
      swiftCode?: string;
    }) => {
      return this.client.post(`/bank_accounts`, payload);
    },

    // post /bank_accounts/actions/archive
    archive: (payload: {
      bankAccount?: {
        id: number;
      };
    }) => {
      return this.client.post(`/bank_accounts/actions/archive`, payload);
    },

    // post /bank_accounts/actions/bulk_create_via_plaid
    bulkCreateViaPlaid: (payload: {
      bankName?: string;
      institutionId?: string;
      plaidPublicToken?: string;
    }) => {
      return this.client.post(
        `/bank_accounts/actions/bulk_create_via_plaid`,
        payload
      );
    },

    // patch /bank_accounts/actions/bulk_update
    bulkUpdate: (
      payload: {
        accountType?:
          | 'personal_checking'
          | 'personal_savings'
          | 'commercial_checking'
          | 'commercial_savings';
        id?: number;
      }[]
    ) => {
      return this.client.patch(`/bank_accounts/actions/bulk_update`, payload);
    },

    // post /bank_accounts/actions/unlink
    unlink: (payload: {
      bankAccount?: {
        id: number;
      };
    }) => {
      return this.client.post(`/bank_accounts/actions/unlink`, payload);
    },

    // post /bank_accounts/actions/verify_same_day_micro_deposit
    verifySameDayMicroDeposit: (payload: {
      bankAccount?: {
        id: number;
      };
    }) => {
      return this.client.post(
        `/bank_accounts/actions/verify_same_day_micro_deposit`,
        payload
      );
    },

    // get /bank_accounts/{id}
    show: (params: { id: number; fields?: string }) => {
      return this.client.get<schemas.BankAccount>(
        `/bank_accounts/${params.id}?${stringify(params)}`
      );
    },

    // patch /bank_accounts/{id}
    update: (
      params: { id: number },
      payload: {
        accountType?:
          | 'personal_checking'
          | 'personal_savings'
          | 'commercial_checking'
          | 'commercial_savings';
        bankName?: string;
        nickname?: string;
      }
    ) => {
      return this.client.patch(`/bank_accounts/${params.id}`, payload);
    },
  };

  // CapitalCalls
  capitalCalls = {
    // get /capital_calls
    index: (
      params: { fields?: string; view?: string; filters?: string } = {}
    ) => {
      return this.client.get<schemas.CapitalCall[]>(
        `/capital_calls?${stringify(params)}`
      );
    },

    // get /capital_calls/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.CapitalCall>(
        `/capital_calls/${params.id}?${stringify(params)}`
      );
    },

    // post /capital_calls/{id}/capital_sent
    capitalSent: (
      params: { id: number },
      payload: {
        details?: string;
      }
    ) => {
      return this.client.post(
        `/capital_calls/${params.id}/capital_sent`,
        payload
      );
    },

    // post /capital_calls/{id}/seen
    seen: (params: { id: number }) => {
      return this.client.post(`/capital_calls/${params.id}/seen`);
    },
  };

  // ContactPreferences
  contactPreferences = {
    // get /contact_preferences
    showSelf: (params: { fields?: string } = {}) => {
      return this.client.get<schemas.ContactPreference>(
        `/contact_preferences?${stringify(params)}`
      );
    },

    // patch /contact_preferences
    updateSelf: (payload: {
      colorMode?: 'dark' | 'light';
      table?: {
        columns: {
          name: string;
          visible: boolean;
        }[];
        name: string;
      };
    }) => {
      return this.client.patch(`/contact_preferences`, payload);
    },
  };

  // ContactUsForm
  contactUsForm = {
    // get /contact_us_form
    index: (params: { fields?: string } = {}) => {
      return this.client.get<schemas.ContactUsFormSetting[]>(
        `/contact_us_form?${stringify(params)}`
      );
    },

    // post /contact_us_form
    create: (payload: {
      codename?: string;
      embedded?: boolean;
      formValues: {
        customAttributeDefinition?: {
          id: number;
        };
        fixedFieldName?:
          | 'first_name'
          | 'last_name'
          | 'email'
          | 'phone'
          | 'accredited'
          | 'message';
        value: string;
      }[];
    }) => {
      return this.client.post(`/contact_us_form`, payload);
    },

    // post /contact_us_form/actions/request_access
    requestAccess: (payload: {
      email: string;
      firstName: string;
      lastName: string;
      phoneNumber?: string;
    }) => {
      return this.client.post(
        `/contact_us_form/actions/request_access`,
        payload
      );
    },

    // post /contact_us_form/actions/unsubscribe
    unsubscribe: (payload: { resubscribe?: boolean; token?: string }) => {
      return this.client.post(`/contact_us_form/actions/unsubscribe`, payload);
    },
  };

  // Contacts
  contacts = {
    // get /contacts
    index: (params: { fields?: string; filters?: string } = {}) => {
      return this.client.get<schemas.Contact[]>(
        `/contacts?${stringify(params)}`
      );
    },

    // patch /contacts/actions/record_mobile_app_review
    recordMobileAppReview: (payload: {
      lastRequestedMobileReviewResponse?: 'yes' | 'no' | 'canceled';
    }) => {
      return this.client.patch(
        `/contacts/actions/record_mobile_app_review`,
        payload
      );
    },

    // get /contacts/actions/self
    showSelf: (params: { fields?: string } = {}) => {
      return this.client.get<schemas.Contact>(
        `/contacts/actions/self?${stringify(params)}`
      );
    },

    // patch /contacts/{id}
    update: (
      params: { id: number },
      payload: {
        addresses?: schemas.Address[];
        countryOfResidence?:
          | 'AF'
          | 'AX'
          | 'AL'
          | 'DZ'
          | 'AS'
          | 'AD'
          | 'AO'
          | 'AI'
          | 'AQ'
          | 'AG'
          | 'AR'
          | 'AM'
          | 'AW'
          | 'AU'
          | 'AT'
          | 'AZ'
          | 'BS'
          | 'BH'
          | 'BD'
          | 'BB'
          | 'BY'
          | 'BE'
          | 'BZ'
          | 'BJ'
          | 'BM'
          | 'BT'
          | 'BO'
          | 'BQ'
          | 'BA'
          | 'BW'
          | 'BV'
          | 'BR'
          | 'IO'
          | 'BN'
          | 'BG'
          | 'BF'
          | 'BI'
          | 'CV'
          | 'KH'
          | 'CM'
          | 'CA'
          | 'KY'
          | 'CF'
          | 'TD'
          | 'CL'
          | 'CN'
          | 'CX'
          | 'CC'
          | 'CO'
          | 'KM'
          | 'CG'
          | 'CD'
          | 'CK'
          | 'CR'
          | 'CI'
          | 'HR'
          | 'CU'
          | 'CW'
          | 'CY'
          | 'CZ'
          | 'DK'
          | 'DJ'
          | 'DM'
          | 'DO'
          | 'EC'
          | 'EG'
          | 'SV'
          | 'GQ'
          | 'ER'
          | 'EE'
          | 'ET'
          | 'FK'
          | 'FO'
          | 'FJ'
          | 'FI'
          | 'FR'
          | 'GF'
          | 'PF'
          | 'TF'
          | 'GA'
          | 'GM'
          | 'GE'
          | 'DE'
          | 'GH'
          | 'GI'
          | 'GR'
          | 'GL'
          | 'GD'
          | 'GP'
          | 'GU'
          | 'GT'
          | 'GG'
          | 'GN'
          | 'GW'
          | 'GY'
          | 'HT'
          | 'HM'
          | 'VA'
          | 'HN'
          | 'HK'
          | 'HU'
          | 'IS'
          | 'IN'
          | 'ID'
          | 'IR'
          | 'IQ'
          | 'IE'
          | 'IM'
          | 'IL'
          | 'IT'
          | 'JM'
          | 'JP'
          | 'JE'
          | 'JO'
          | 'KZ'
          | 'KE'
          | 'KI'
          | 'KP'
          | 'KR'
          | 'KW'
          | 'KG'
          | 'LA'
          | 'LV'
          | 'LB'
          | 'LS'
          | 'LR'
          | 'LY'
          | 'LI'
          | 'LT'
          | 'LU'
          | 'MO'
          | 'MK'
          | 'MG'
          | 'MW'
          | 'MY'
          | 'MV'
          | 'ML'
          | 'MT'
          | 'MH'
          | 'MQ'
          | 'MR'
          | 'MU'
          | 'YT'
          | 'MX'
          | 'FM'
          | 'MD'
          | 'MC'
          | 'MN'
          | 'ME'
          | 'MS'
          | 'MA'
          | 'MZ'
          | 'MM'
          | 'NA'
          | 'NR'
          | 'NP'
          | 'NL'
          | 'NC'
          | 'NZ'
          | 'NI'
          | 'NE'
          | 'NG'
          | 'NU'
          | 'NF'
          | 'MP'
          | 'NO'
          | 'OM'
          | 'PK'
          | 'PW'
          | 'PS'
          | 'PA'
          | 'PG'
          | 'PY'
          | 'PE'
          | 'PH'
          | 'PN'
          | 'PL'
          | 'PT'
          | 'PR'
          | 'QA'
          | 'RE'
          | 'RO'
          | 'RU'
          | 'RW'
          | 'BL'
          | 'SH'
          | 'KN'
          | 'LC'
          | 'MF'
          | 'PM'
          | 'VC'
          | 'WS'
          | 'SM'
          | 'ST'
          | 'SA'
          | 'SN'
          | 'RS'
          | 'SC'
          | 'SL'
          | 'SG'
          | 'SX'
          | 'SK'
          | 'SI'
          | 'SB'
          | 'SO'
          | 'ZA'
          | 'GS'
          | 'SS'
          | 'ES'
          | 'LK'
          | 'SD'
          | 'SR'
          | 'SJ'
          | 'SZ'
          | 'SE'
          | 'CH'
          | 'SY'
          | 'TW'
          | 'TJ'
          | 'TZ'
          | 'TH'
          | 'TL'
          | 'TG'
          | 'TK'
          | 'TO'
          | 'TT'
          | 'TN'
          | 'TR'
          | 'TM'
          | 'TC'
          | 'TV'
          | 'UG'
          | 'UA'
          | 'AE'
          | 'GB'
          | 'US'
          | 'UM'
          | 'UY'
          | 'UZ'
          | 'VU'
          | 'VE'
          | 'VN'
          | 'VG'
          | 'VI'
          | 'WF'
          | 'EH'
          | 'YE'
          | 'ZM'
          | 'ZW';
        dob?: Date;
        emails?: schemas.ContactEmail[];
        fincenId?: string;
        middleName?: string;
        phones?: schemas.ContactPhone[];
      }
    ) => {
      return this.client.patch(`/contacts/${params.id}`, payload);
    },
  };

  // Contributions
  contributions = {
    // get /contributions
    index: (
      params: { fields?: string; filters?: string; view?: string } = {}
    ) => {
      return this.client.get<schemas.Contribution[]>(
        `/contributions?${stringify(params)}`
      );
    },

    // get /contributions/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.Contribution>(
        `/contributions/${params.id}?${stringify(params)}`
      );
    },
  };

  // Debug
  debug = {
    // post /debug/actions/generate_error
    generateError: (payload: {
      errorType?:
        | 'bad_arguments'
        | 'be_runtime'
        | 'not_found'
        | 'validation_error'
        | 'invalid_api'
        | 'unprocessable_payload'
        | 'error_code'
        | '503'
        | 'bad_gateway';
      fields?: string;
      message?: string;
    }) => {
      return this.client.post(`/debug/actions/generate_error`, payload);
    },
  };

  // DistributionItems
  distributionItems = {
    // get /distribution_items
    index: (
      params: { fields?: string; filters?: string; view?: string } = {}
    ) => {
      return this.client.get<schemas.DistributionItem[]>(
        `/distribution_items?${stringify(params)}`
      );
    },

    // get /distribution_items/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.DistributionItem>(
        `/distribution_items/${params.id}?${stringify(params)}`
      );
    },
  };

  // DistributionWithholdings
  distributionWithholdings = {
    // get /distribution_withholdings
    index: (
      params: { fields?: string; filters?: string; view?: string } = {}
    ) => {
      return this.client.get<schemas.DistributionWithholding[]>(
        `/distribution_withholdings?${stringify(params)}`
      );
    },
  };

  // Distributions
  distributions = {
    // get /distributions
    index: (
      params: { fields?: string; filters?: string; view?: string } = {}
    ) => {
      return this.client.get<schemas.Distribution[]>(
        `/distributions?${stringify(params)}`
      );
    },

    // get /distributions/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.Distribution>(
        `/distributions/${params.id}?${stringify(params)}`
      );
    },
  };

  // DocumentShares
  documentShares = {
    // get /document_shares
    index: (
      params: { fields?: string; filters?: string; view?: string } = {}
    ) => {
      return this.client.get<schemas.DocumentShare[]>(
        `/document_shares?${stringify(params)}`
      );
    },

    // get /document_shares/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.DocumentShare>(
        `/document_shares/${params.id}?${stringify(params)}`
      );
    },
  };

  // Documents
  documents = {
    // post /documents
    create: () => {
      return this.client.post(`/documents`);
    },

    // get /documents/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.Document>(
        `/documents/${params.id}?${stringify(params)}`
      );
    },
  };

  // Feedback
  feedback = {
    // post /feedback
    create: (payload: {
      browserHeight?: number;
      browserWidth?: number;
      feature?: string;
      feedback?: string;
      rating?: number;
      recipient?: string;
      screenHeight?: number;
      screenWidth?: number;
      uiVersion?: string;
      url?: string;
    }) => {
      return this.client.post(`/feedback`, payload);
    },
  };

  // Firm
  firm = {
    // get /firm
    info: () => {
      return this.client.get<schemas.FirmInfo>(`/firm`);
    },
  };

  // Funds
  funds = {
    // get /funds
    index: (
      params: { fields?: string; filters?: string; view?: string } = {}
    ) => {
      return this.client.get<schemas.Fund[]>(`/funds?${stringify(params)}`);
    },

    // post /funds/actions/index
    indexWithPost: (payload: {
      fields?: string;
      filters?: string;
      view?: string;
    }) => {
      return this.client.post<schemas.Fund[]>(`/funds/actions/index`, payload);
    },

    // get /funds/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.Fund>(
        `/funds/${params.id}?${stringify(params)}`
      );
    },

    // post /funds/{id}/actions/show
    showWithPost: (
      params: { id: number },
      payload: {
        fields?: string;
        view?: string;
      }
    ) => {
      return this.client.post<schemas.Fund>(
        `/funds/${params.id}/actions/show`,
        payload
      );
    },
  };

  // GeneralPartnerProfileShares
  generalPartnerProfileShares = {
    // get /general_partner_profile_shares
    index: (params: { fields?: string; filters?: string } = {}) => {
      return this.client.get<schemas.GeneralPartnerProfileShare[]>(
        `/general_partner_profile_shares?${stringify(params)}`
      );
    },
  };

  // GeneralPartners
  generalPartners = {
    // get /general_partners/{uuid}
    show: (params: { uuid: string; fields?: string }) => {
      return this.client.get<schemas.GeneralPartner>(
        `/general_partners/${params.uuid}?${stringify(params)}`
      );
    },
  };

  // InvestorContacts
  investorContacts = {
    // post /investor_contacts
    create: (payload: {
      contact?: {
        id: number;
      };
      investor?: {
        id: number;
      };
      investorContactRules?: schemas.InvestorContactRule[];
      isSigner?: boolean;
      newContact?: {
        emails?: schemas.ContactEmail[];
        firstName?: string;
        lastName?: string;
        phones?: schemas.ContactPhone[];
      };
    }) => {
      return this.client.post(`/investor_contacts`, payload);
    },
  };

  // Investors
  investors = {
    // get /investors
    index: (
      params: { fields?: string; filters?: string; view?: string } = {}
    ) => {
      return this.client.get<schemas.Investor[]>(
        `/investors?${stringify(params)}`
      );
    },

    // get /investors/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.Investor>(
        `/investors/${params.id}?${stringify(params)}`
      );
    },

    // patch /investors/{id}
    update: (
      params: { id: number },
      payload: {
        bank?: {
          id?: number;
          payeeName?: string;
          paymentAddress?: {
            city?: string;
            countryCode?:
              | 'AF'
              | 'AX'
              | 'AL'
              | 'DZ'
              | 'AS'
              | 'AD'
              | 'AO'
              | 'AI'
              | 'AQ'
              | 'AG'
              | 'AR'
              | 'AM'
              | 'AW'
              | 'AU'
              | 'AT'
              | 'AZ'
              | 'BS'
              | 'BH'
              | 'BD'
              | 'BB'
              | 'BY'
              | 'BE'
              | 'BZ'
              | 'BJ'
              | 'BM'
              | 'BT'
              | 'BO'
              | 'BQ'
              | 'BA'
              | 'BW'
              | 'BV'
              | 'BR'
              | 'IO'
              | 'BN'
              | 'BG'
              | 'BF'
              | 'BI'
              | 'CV'
              | 'KH'
              | 'CM'
              | 'CA'
              | 'KY'
              | 'CF'
              | 'TD'
              | 'CL'
              | 'CN'
              | 'CX'
              | 'CC'
              | 'CO'
              | 'KM'
              | 'CG'
              | 'CD'
              | 'CK'
              | 'CR'
              | 'CI'
              | 'HR'
              | 'CU'
              | 'CW'
              | 'CY'
              | 'CZ'
              | 'DK'
              | 'DJ'
              | 'DM'
              | 'DO'
              | 'EC'
              | 'EG'
              | 'SV'
              | 'GQ'
              | 'ER'
              | 'EE'
              | 'ET'
              | 'FK'
              | 'FO'
              | 'FJ'
              | 'FI'
              | 'FR'
              | 'GF'
              | 'PF'
              | 'TF'
              | 'GA'
              | 'GM'
              | 'GE'
              | 'DE'
              | 'GH'
              | 'GI'
              | 'GR'
              | 'GL'
              | 'GD'
              | 'GP'
              | 'GU'
              | 'GT'
              | 'GG'
              | 'GN'
              | 'GW'
              | 'GY'
              | 'HT'
              | 'HM'
              | 'VA'
              | 'HN'
              | 'HK'
              | 'HU'
              | 'IS'
              | 'IN'
              | 'ID'
              | 'IR'
              | 'IQ'
              | 'IE'
              | 'IM'
              | 'IL'
              | 'IT'
              | 'JM'
              | 'JP'
              | 'JE'
              | 'JO'
              | 'KZ'
              | 'KE'
              | 'KI'
              | 'KP'
              | 'KR'
              | 'KW'
              | 'KG'
              | 'LA'
              | 'LV'
              | 'LB'
              | 'LS'
              | 'LR'
              | 'LY'
              | 'LI'
              | 'LT'
              | 'LU'
              | 'MO'
              | 'MK'
              | 'MG'
              | 'MW'
              | 'MY'
              | 'MV'
              | 'ML'
              | 'MT'
              | 'MH'
              | 'MQ'
              | 'MR'
              | 'MU'
              | 'YT'
              | 'MX'
              | 'FM'
              | 'MD'
              | 'MC'
              | 'MN'
              | 'ME'
              | 'MS'
              | 'MA'
              | 'MZ'
              | 'MM'
              | 'NA'
              | 'NR'
              | 'NP'
              | 'NL'
              | 'NC'
              | 'NZ'
              | 'NI'
              | 'NE'
              | 'NG'
              | 'NU'
              | 'NF'
              | 'MP'
              | 'NO'
              | 'OM'
              | 'PK'
              | 'PW'
              | 'PS'
              | 'PA'
              | 'PG'
              | 'PY'
              | 'PE'
              | 'PH'
              | 'PN'
              | 'PL'
              | 'PT'
              | 'PR'
              | 'QA'
              | 'RE'
              | 'RO'
              | 'RU'
              | 'RW'
              | 'BL'
              | 'SH'
              | 'KN'
              | 'LC'
              | 'MF'
              | 'PM'
              | 'VC'
              | 'WS'
              | 'SM'
              | 'ST'
              | 'SA'
              | 'SN'
              | 'RS'
              | 'SC'
              | 'SL'
              | 'SG'
              | 'SX'
              | 'SK'
              | 'SI'
              | 'SB'
              | 'SO'
              | 'ZA'
              | 'GS'
              | 'SS'
              | 'ES'
              | 'LK'
              | 'SD'
              | 'SR'
              | 'SJ'
              | 'SZ'
              | 'SE'
              | 'CH'
              | 'SY'
              | 'TW'
              | 'TJ'
              | 'TZ'
              | 'TH'
              | 'TL'
              | 'TG'
              | 'TK'
              | 'TO'
              | 'TT'
              | 'TN'
              | 'TR'
              | 'TM'
              | 'TC'
              | 'TV'
              | 'UG'
              | 'UA'
              | 'AE'
              | 'GB'
              | 'US'
              | 'UM'
              | 'UY'
              | 'UZ'
              | 'VU'
              | 'VE'
              | 'VN'
              | 'VG'
              | 'VI'
              | 'WF'
              | 'EH'
              | 'YE'
              | 'ZM'
              | 'ZW';
            label?: string;
            pobox?: string;
            postalCode?: string;
            state?: string;
            street1?: string;
            street2?: string;
          };
          settlementMethod?: 'check' | 'electronic' | 'wire';
        };
        countryOfIncorporation?:
          | 'AF'
          | 'AX'
          | 'AL'
          | 'DZ'
          | 'AS'
          | 'AD'
          | 'AO'
          | 'AI'
          | 'AQ'
          | 'AG'
          | 'AR'
          | 'AM'
          | 'AW'
          | 'AU'
          | 'AT'
          | 'AZ'
          | 'BS'
          | 'BH'
          | 'BD'
          | 'BB'
          | 'BY'
          | 'BE'
          | 'BZ'
          | 'BJ'
          | 'BM'
          | 'BT'
          | 'BO'
          | 'BQ'
          | 'BA'
          | 'BW'
          | 'BV'
          | 'BR'
          | 'IO'
          | 'BN'
          | 'BG'
          | 'BF'
          | 'BI'
          | 'CV'
          | 'KH'
          | 'CM'
          | 'CA'
          | 'KY'
          | 'CF'
          | 'TD'
          | 'CL'
          | 'CN'
          | 'CX'
          | 'CC'
          | 'CO'
          | 'KM'
          | 'CG'
          | 'CD'
          | 'CK'
          | 'CR'
          | 'CI'
          | 'HR'
          | 'CU'
          | 'CW'
          | 'CY'
          | 'CZ'
          | 'DK'
          | 'DJ'
          | 'DM'
          | 'DO'
          | 'EC'
          | 'EG'
          | 'SV'
          | 'GQ'
          | 'ER'
          | 'EE'
          | 'ET'
          | 'FK'
          | 'FO'
          | 'FJ'
          | 'FI'
          | 'FR'
          | 'GF'
          | 'PF'
          | 'TF'
          | 'GA'
          | 'GM'
          | 'GE'
          | 'DE'
          | 'GH'
          | 'GI'
          | 'GR'
          | 'GL'
          | 'GD'
          | 'GP'
          | 'GU'
          | 'GT'
          | 'GG'
          | 'GN'
          | 'GW'
          | 'GY'
          | 'HT'
          | 'HM'
          | 'VA'
          | 'HN'
          | 'HK'
          | 'HU'
          | 'IS'
          | 'IN'
          | 'ID'
          | 'IR'
          | 'IQ'
          | 'IE'
          | 'IM'
          | 'IL'
          | 'IT'
          | 'JM'
          | 'JP'
          | 'JE'
          | 'JO'
          | 'KZ'
          | 'KE'
          | 'KI'
          | 'KP'
          | 'KR'
          | 'KW'
          | 'KG'
          | 'LA'
          | 'LV'
          | 'LB'
          | 'LS'
          | 'LR'
          | 'LY'
          | 'LI'
          | 'LT'
          | 'LU'
          | 'MO'
          | 'MK'
          | 'MG'
          | 'MW'
          | 'MY'
          | 'MV'
          | 'ML'
          | 'MT'
          | 'MH'
          | 'MQ'
          | 'MR'
          | 'MU'
          | 'YT'
          | 'MX'
          | 'FM'
          | 'MD'
          | 'MC'
          | 'MN'
          | 'ME'
          | 'MS'
          | 'MA'
          | 'MZ'
          | 'MM'
          | 'NA'
          | 'NR'
          | 'NP'
          | 'NL'
          | 'NC'
          | 'NZ'
          | 'NI'
          | 'NE'
          | 'NG'
          | 'NU'
          | 'NF'
          | 'MP'
          | 'NO'
          | 'OM'
          | 'PK'
          | 'PW'
          | 'PS'
          | 'PA'
          | 'PG'
          | 'PY'
          | 'PE'
          | 'PH'
          | 'PN'
          | 'PL'
          | 'PT'
          | 'PR'
          | 'QA'
          | 'RE'
          | 'RO'
          | 'RU'
          | 'RW'
          | 'BL'
          | 'SH'
          | 'KN'
          | 'LC'
          | 'MF'
          | 'PM'
          | 'VC'
          | 'WS'
          | 'SM'
          | 'ST'
          | 'SA'
          | 'SN'
          | 'RS'
          | 'SC'
          | 'SL'
          | 'SG'
          | 'SX'
          | 'SK'
          | 'SI'
          | 'SB'
          | 'SO'
          | 'ZA'
          | 'GS'
          | 'SS'
          | 'ES'
          | 'LK'
          | 'SD'
          | 'SR'
          | 'SJ'
          | 'SZ'
          | 'SE'
          | 'CH'
          | 'SY'
          | 'TW'
          | 'TJ'
          | 'TZ'
          | 'TH'
          | 'TL'
          | 'TG'
          | 'TK'
          | 'TO'
          | 'TT'
          | 'TN'
          | 'TR'
          | 'TM'
          | 'TC'
          | 'TV'
          | 'UG'
          | 'UA'
          | 'AE'
          | 'GB'
          | 'US'
          | 'UM'
          | 'UY'
          | 'UZ'
          | 'VU'
          | 'VE'
          | 'VN'
          | 'VG'
          | 'VI'
          | 'WF'
          | 'EH'
          | 'YE'
          | 'ZM'
          | 'ZW';
        entityType?:
          | 'individual'
          | 'corporation'
          | 'estate_or_trust'
          | 'partnership'
          | 'exempt_organization'
          | 'ira'
          | 'foreign_government';
        ffcAccountNumber?: string;
        ffcCustodianName?: string;
        name?: string;
        taxAddress?: {
          city?: string;
          countryCode?:
            | 'AF'
            | 'AX'
            | 'AL'
            | 'DZ'
            | 'AS'
            | 'AD'
            | 'AO'
            | 'AI'
            | 'AQ'
            | 'AG'
            | 'AR'
            | 'AM'
            | 'AW'
            | 'AU'
            | 'AT'
            | 'AZ'
            | 'BS'
            | 'BH'
            | 'BD'
            | 'BB'
            | 'BY'
            | 'BE'
            | 'BZ'
            | 'BJ'
            | 'BM'
            | 'BT'
            | 'BO'
            | 'BQ'
            | 'BA'
            | 'BW'
            | 'BV'
            | 'BR'
            | 'IO'
            | 'BN'
            | 'BG'
            | 'BF'
            | 'BI'
            | 'CV'
            | 'KH'
            | 'CM'
            | 'CA'
            | 'KY'
            | 'CF'
            | 'TD'
            | 'CL'
            | 'CN'
            | 'CX'
            | 'CC'
            | 'CO'
            | 'KM'
            | 'CG'
            | 'CD'
            | 'CK'
            | 'CR'
            | 'CI'
            | 'HR'
            | 'CU'
            | 'CW'
            | 'CY'
            | 'CZ'
            | 'DK'
            | 'DJ'
            | 'DM'
            | 'DO'
            | 'EC'
            | 'EG'
            | 'SV'
            | 'GQ'
            | 'ER'
            | 'EE'
            | 'ET'
            | 'FK'
            | 'FO'
            | 'FJ'
            | 'FI'
            | 'FR'
            | 'GF'
            | 'PF'
            | 'TF'
            | 'GA'
            | 'GM'
            | 'GE'
            | 'DE'
            | 'GH'
            | 'GI'
            | 'GR'
            | 'GL'
            | 'GD'
            | 'GP'
            | 'GU'
            | 'GT'
            | 'GG'
            | 'GN'
            | 'GW'
            | 'GY'
            | 'HT'
            | 'HM'
            | 'VA'
            | 'HN'
            | 'HK'
            | 'HU'
            | 'IS'
            | 'IN'
            | 'ID'
            | 'IR'
            | 'IQ'
            | 'IE'
            | 'IM'
            | 'IL'
            | 'IT'
            | 'JM'
            | 'JP'
            | 'JE'
            | 'JO'
            | 'KZ'
            | 'KE'
            | 'KI'
            | 'KP'
            | 'KR'
            | 'KW'
            | 'KG'
            | 'LA'
            | 'LV'
            | 'LB'
            | 'LS'
            | 'LR'
            | 'LY'
            | 'LI'
            | 'LT'
            | 'LU'
            | 'MO'
            | 'MK'
            | 'MG'
            | 'MW'
            | 'MY'
            | 'MV'
            | 'ML'
            | 'MT'
            | 'MH'
            | 'MQ'
            | 'MR'
            | 'MU'
            | 'YT'
            | 'MX'
            | 'FM'
            | 'MD'
            | 'MC'
            | 'MN'
            | 'ME'
            | 'MS'
            | 'MA'
            | 'MZ'
            | 'MM'
            | 'NA'
            | 'NR'
            | 'NP'
            | 'NL'
            | 'NC'
            | 'NZ'
            | 'NI'
            | 'NE'
            | 'NG'
            | 'NU'
            | 'NF'
            | 'MP'
            | 'NO'
            | 'OM'
            | 'PK'
            | 'PW'
            | 'PS'
            | 'PA'
            | 'PG'
            | 'PY'
            | 'PE'
            | 'PH'
            | 'PN'
            | 'PL'
            | 'PT'
            | 'PR'
            | 'QA'
            | 'RE'
            | 'RO'
            | 'RU'
            | 'RW'
            | 'BL'
            | 'SH'
            | 'KN'
            | 'LC'
            | 'MF'
            | 'PM'
            | 'VC'
            | 'WS'
            | 'SM'
            | 'ST'
            | 'SA'
            | 'SN'
            | 'RS'
            | 'SC'
            | 'SL'
            | 'SG'
            | 'SX'
            | 'SK'
            | 'SI'
            | 'SB'
            | 'SO'
            | 'ZA'
            | 'GS'
            | 'SS'
            | 'ES'
            | 'LK'
            | 'SD'
            | 'SR'
            | 'SJ'
            | 'SZ'
            | 'SE'
            | 'CH'
            | 'SY'
            | 'TW'
            | 'TJ'
            | 'TZ'
            | 'TH'
            | 'TL'
            | 'TG'
            | 'TK'
            | 'TO'
            | 'TT'
            | 'TN'
            | 'TR'
            | 'TM'
            | 'TC'
            | 'TV'
            | 'UG'
            | 'UA'
            | 'AE'
            | 'GB'
            | 'US'
            | 'UM'
            | 'UY'
            | 'UZ'
            | 'VU'
            | 'VE'
            | 'VN'
            | 'VG'
            | 'VI'
            | 'WF'
            | 'EH'
            | 'YE'
            | 'ZM'
            | 'ZW';
          label?: string;
          pobox?: string;
          postalCode?: string;
          state?: string;
          street1?: string;
          street2?: string;
        };
        tin?: string;
      }
    ) => {
      return this.client.patch(`/investors/${params.id}`, payload);
    },
  };

  // IPContributions
  iPContributions = {
    // get /ip_contributions
    index: (
      params: { filters?: string; view?: string; fields?: string } = {}
    ) => {
      return this.client.get<schemas.IPContribution[]>(
        `/ip_contributions?${stringify(params)}`
      );
    },

    // post /ip_contributions/actions/send_payment
    sendPayment: (payload: {
      amount?: number;
      bankAccount?: {
        id: number;
      };
      capitalCallItem?: {
        id: number;
      };
      contact?: {
        countryOfResidence?:
          | 'AF'
          | 'AX'
          | 'AL'
          | 'DZ'
          | 'AS'
          | 'AD'
          | 'AO'
          | 'AI'
          | 'AQ'
          | 'AG'
          | 'AR'
          | 'AM'
          | 'AW'
          | 'AU'
          | 'AT'
          | 'AZ'
          | 'BS'
          | 'BH'
          | 'BD'
          | 'BB'
          | 'BY'
          | 'BE'
          | 'BZ'
          | 'BJ'
          | 'BM'
          | 'BT'
          | 'BO'
          | 'BQ'
          | 'BA'
          | 'BW'
          | 'BV'
          | 'BR'
          | 'IO'
          | 'BN'
          | 'BG'
          | 'BF'
          | 'BI'
          | 'CV'
          | 'KH'
          | 'CM'
          | 'CA'
          | 'KY'
          | 'CF'
          | 'TD'
          | 'CL'
          | 'CN'
          | 'CX'
          | 'CC'
          | 'CO'
          | 'KM'
          | 'CG'
          | 'CD'
          | 'CK'
          | 'CR'
          | 'CI'
          | 'HR'
          | 'CU'
          | 'CW'
          | 'CY'
          | 'CZ'
          | 'DK'
          | 'DJ'
          | 'DM'
          | 'DO'
          | 'EC'
          | 'EG'
          | 'SV'
          | 'GQ'
          | 'ER'
          | 'EE'
          | 'ET'
          | 'FK'
          | 'FO'
          | 'FJ'
          | 'FI'
          | 'FR'
          | 'GF'
          | 'PF'
          | 'TF'
          | 'GA'
          | 'GM'
          | 'GE'
          | 'DE'
          | 'GH'
          | 'GI'
          | 'GR'
          | 'GL'
          | 'GD'
          | 'GP'
          | 'GU'
          | 'GT'
          | 'GG'
          | 'GN'
          | 'GW'
          | 'GY'
          | 'HT'
          | 'HM'
          | 'VA'
          | 'HN'
          | 'HK'
          | 'HU'
          | 'IS'
          | 'IN'
          | 'ID'
          | 'IR'
          | 'IQ'
          | 'IE'
          | 'IM'
          | 'IL'
          | 'IT'
          | 'JM'
          | 'JP'
          | 'JE'
          | 'JO'
          | 'KZ'
          | 'KE'
          | 'KI'
          | 'KP'
          | 'KR'
          | 'KW'
          | 'KG'
          | 'LA'
          | 'LV'
          | 'LB'
          | 'LS'
          | 'LR'
          | 'LY'
          | 'LI'
          | 'LT'
          | 'LU'
          | 'MO'
          | 'MK'
          | 'MG'
          | 'MW'
          | 'MY'
          | 'MV'
          | 'ML'
          | 'MT'
          | 'MH'
          | 'MQ'
          | 'MR'
          | 'MU'
          | 'YT'
          | 'MX'
          | 'FM'
          | 'MD'
          | 'MC'
          | 'MN'
          | 'ME'
          | 'MS'
          | 'MA'
          | 'MZ'
          | 'MM'
          | 'NA'
          | 'NR'
          | 'NP'
          | 'NL'
          | 'NC'
          | 'NZ'
          | 'NI'
          | 'NE'
          | 'NG'
          | 'NU'
          | 'NF'
          | 'MP'
          | 'NO'
          | 'OM'
          | 'PK'
          | 'PW'
          | 'PS'
          | 'PA'
          | 'PG'
          | 'PY'
          | 'PE'
          | 'PH'
          | 'PN'
          | 'PL'
          | 'PT'
          | 'PR'
          | 'QA'
          | 'RE'
          | 'RO'
          | 'RU'
          | 'RW'
          | 'BL'
          | 'SH'
          | 'KN'
          | 'LC'
          | 'MF'
          | 'PM'
          | 'VC'
          | 'WS'
          | 'SM'
          | 'ST'
          | 'SA'
          | 'SN'
          | 'RS'
          | 'SC'
          | 'SL'
          | 'SG'
          | 'SX'
          | 'SK'
          | 'SI'
          | 'SB'
          | 'SO'
          | 'ZA'
          | 'GS'
          | 'SS'
          | 'ES'
          | 'LK'
          | 'SD'
          | 'SR'
          | 'SJ'
          | 'SZ'
          | 'SE'
          | 'CH'
          | 'SY'
          | 'TW'
          | 'TJ'
          | 'TZ'
          | 'TH'
          | 'TL'
          | 'TG'
          | 'TK'
          | 'TO'
          | 'TT'
          | 'TN'
          | 'TR'
          | 'TM'
          | 'TC'
          | 'TV'
          | 'UG'
          | 'UA'
          | 'AE'
          | 'GB'
          | 'US'
          | 'UM'
          | 'UY'
          | 'UZ'
          | 'VU'
          | 'VE'
          | 'VN'
          | 'VG'
          | 'VI'
          | 'WF'
          | 'EH'
          | 'YE'
          | 'ZM'
          | 'ZW';
        dob?: Date;
        id: number;
        middleName?: string;
      };
      investor?: {
        bank?: {
          payeeName?: string;
        };
        countryOfIncorporation?:
          | 'AF'
          | 'AX'
          | 'AL'
          | 'DZ'
          | 'AS'
          | 'AD'
          | 'AO'
          | 'AI'
          | 'AQ'
          | 'AG'
          | 'AR'
          | 'AM'
          | 'AW'
          | 'AU'
          | 'AT'
          | 'AZ'
          | 'BS'
          | 'BH'
          | 'BD'
          | 'BB'
          | 'BY'
          | 'BE'
          | 'BZ'
          | 'BJ'
          | 'BM'
          | 'BT'
          | 'BO'
          | 'BQ'
          | 'BA'
          | 'BW'
          | 'BV'
          | 'BR'
          | 'IO'
          | 'BN'
          | 'BG'
          | 'BF'
          | 'BI'
          | 'CV'
          | 'KH'
          | 'CM'
          | 'CA'
          | 'KY'
          | 'CF'
          | 'TD'
          | 'CL'
          | 'CN'
          | 'CX'
          | 'CC'
          | 'CO'
          | 'KM'
          | 'CG'
          | 'CD'
          | 'CK'
          | 'CR'
          | 'CI'
          | 'HR'
          | 'CU'
          | 'CW'
          | 'CY'
          | 'CZ'
          | 'DK'
          | 'DJ'
          | 'DM'
          | 'DO'
          | 'EC'
          | 'EG'
          | 'SV'
          | 'GQ'
          | 'ER'
          | 'EE'
          | 'ET'
          | 'FK'
          | 'FO'
          | 'FJ'
          | 'FI'
          | 'FR'
          | 'GF'
          | 'PF'
          | 'TF'
          | 'GA'
          | 'GM'
          | 'GE'
          | 'DE'
          | 'GH'
          | 'GI'
          | 'GR'
          | 'GL'
          | 'GD'
          | 'GP'
          | 'GU'
          | 'GT'
          | 'GG'
          | 'GN'
          | 'GW'
          | 'GY'
          | 'HT'
          | 'HM'
          | 'VA'
          | 'HN'
          | 'HK'
          | 'HU'
          | 'IS'
          | 'IN'
          | 'ID'
          | 'IR'
          | 'IQ'
          | 'IE'
          | 'IM'
          | 'IL'
          | 'IT'
          | 'JM'
          | 'JP'
          | 'JE'
          | 'JO'
          | 'KZ'
          | 'KE'
          | 'KI'
          | 'KP'
          | 'KR'
          | 'KW'
          | 'KG'
          | 'LA'
          | 'LV'
          | 'LB'
          | 'LS'
          | 'LR'
          | 'LY'
          | 'LI'
          | 'LT'
          | 'LU'
          | 'MO'
          | 'MK'
          | 'MG'
          | 'MW'
          | 'MY'
          | 'MV'
          | 'ML'
          | 'MT'
          | 'MH'
          | 'MQ'
          | 'MR'
          | 'MU'
          | 'YT'
          | 'MX'
          | 'FM'
          | 'MD'
          | 'MC'
          | 'MN'
          | 'ME'
          | 'MS'
          | 'MA'
          | 'MZ'
          | 'MM'
          | 'NA'
          | 'NR'
          | 'NP'
          | 'NL'
          | 'NC'
          | 'NZ'
          | 'NI'
          | 'NE'
          | 'NG'
          | 'NU'
          | 'NF'
          | 'MP'
          | 'NO'
          | 'OM'
          | 'PK'
          | 'PW'
          | 'PS'
          | 'PA'
          | 'PG'
          | 'PY'
          | 'PE'
          | 'PH'
          | 'PN'
          | 'PL'
          | 'PT'
          | 'PR'
          | 'QA'
          | 'RE'
          | 'RO'
          | 'RU'
          | 'RW'
          | 'BL'
          | 'SH'
          | 'KN'
          | 'LC'
          | 'MF'
          | 'PM'
          | 'VC'
          | 'WS'
          | 'SM'
          | 'ST'
          | 'SA'
          | 'SN'
          | 'RS'
          | 'SC'
          | 'SL'
          | 'SG'
          | 'SX'
          | 'SK'
          | 'SI'
          | 'SB'
          | 'SO'
          | 'ZA'
          | 'GS'
          | 'SS'
          | 'ES'
          | 'LK'
          | 'SD'
          | 'SR'
          | 'SJ'
          | 'SZ'
          | 'SE'
          | 'CH'
          | 'SY'
          | 'TW'
          | 'TJ'
          | 'TZ'
          | 'TH'
          | 'TL'
          | 'TG'
          | 'TK'
          | 'TO'
          | 'TT'
          | 'TN'
          | 'TR'
          | 'TM'
          | 'TC'
          | 'TV'
          | 'UG'
          | 'UA'
          | 'AE'
          | 'GB'
          | 'US'
          | 'UM'
          | 'UY'
          | 'UZ'
          | 'VU'
          | 'VE'
          | 'VN'
          | 'VG'
          | 'VI'
          | 'WF'
          | 'EH'
          | 'YE'
          | 'ZM'
          | 'ZW';
        entityType?:
          | 'individual'
          | 'corporation'
          | 'estate_or_trust'
          | 'partnership'
          | 'exempt_organization'
          | 'ira'
          | 'foreign_government';
        id: number;
        taxAddress?: {
          city?: string;
          countryCode?:
            | 'AF'
            | 'AX'
            | 'AL'
            | 'DZ'
            | 'AS'
            | 'AD'
            | 'AO'
            | 'AI'
            | 'AQ'
            | 'AG'
            | 'AR'
            | 'AM'
            | 'AW'
            | 'AU'
            | 'AT'
            | 'AZ'
            | 'BS'
            | 'BH'
            | 'BD'
            | 'BB'
            | 'BY'
            | 'BE'
            | 'BZ'
            | 'BJ'
            | 'BM'
            | 'BT'
            | 'BO'
            | 'BQ'
            | 'BA'
            | 'BW'
            | 'BV'
            | 'BR'
            | 'IO'
            | 'BN'
            | 'BG'
            | 'BF'
            | 'BI'
            | 'CV'
            | 'KH'
            | 'CM'
            | 'CA'
            | 'KY'
            | 'CF'
            | 'TD'
            | 'CL'
            | 'CN'
            | 'CX'
            | 'CC'
            | 'CO'
            | 'KM'
            | 'CG'
            | 'CD'
            | 'CK'
            | 'CR'
            | 'CI'
            | 'HR'
            | 'CU'
            | 'CW'
            | 'CY'
            | 'CZ'
            | 'DK'
            | 'DJ'
            | 'DM'
            | 'DO'
            | 'EC'
            | 'EG'
            | 'SV'
            | 'GQ'
            | 'ER'
            | 'EE'
            | 'ET'
            | 'FK'
            | 'FO'
            | 'FJ'
            | 'FI'
            | 'FR'
            | 'GF'
            | 'PF'
            | 'TF'
            | 'GA'
            | 'GM'
            | 'GE'
            | 'DE'
            | 'GH'
            | 'GI'
            | 'GR'
            | 'GL'
            | 'GD'
            | 'GP'
            | 'GU'
            | 'GT'
            | 'GG'
            | 'GN'
            | 'GW'
            | 'GY'
            | 'HT'
            | 'HM'
            | 'VA'
            | 'HN'
            | 'HK'
            | 'HU'
            | 'IS'
            | 'IN'
            | 'ID'
            | 'IR'
            | 'IQ'
            | 'IE'
            | 'IM'
            | 'IL'
            | 'IT'
            | 'JM'
            | 'JP'
            | 'JE'
            | 'JO'
            | 'KZ'
            | 'KE'
            | 'KI'
            | 'KP'
            | 'KR'
            | 'KW'
            | 'KG'
            | 'LA'
            | 'LV'
            | 'LB'
            | 'LS'
            | 'LR'
            | 'LY'
            | 'LI'
            | 'LT'
            | 'LU'
            | 'MO'
            | 'MK'
            | 'MG'
            | 'MW'
            | 'MY'
            | 'MV'
            | 'ML'
            | 'MT'
            | 'MH'
            | 'MQ'
            | 'MR'
            | 'MU'
            | 'YT'
            | 'MX'
            | 'FM'
            | 'MD'
            | 'MC'
            | 'MN'
            | 'ME'
            | 'MS'
            | 'MA'
            | 'MZ'
            | 'MM'
            | 'NA'
            | 'NR'
            | 'NP'
            | 'NL'
            | 'NC'
            | 'NZ'
            | 'NI'
            | 'NE'
            | 'NG'
            | 'NU'
            | 'NF'
            | 'MP'
            | 'NO'
            | 'OM'
            | 'PK'
            | 'PW'
            | 'PS'
            | 'PA'
            | 'PG'
            | 'PY'
            | 'PE'
            | 'PH'
            | 'PN'
            | 'PL'
            | 'PT'
            | 'PR'
            | 'QA'
            | 'RE'
            | 'RO'
            | 'RU'
            | 'RW'
            | 'BL'
            | 'SH'
            | 'KN'
            | 'LC'
            | 'MF'
            | 'PM'
            | 'VC'
            | 'WS'
            | 'SM'
            | 'ST'
            | 'SA'
            | 'SN'
            | 'RS'
            | 'SC'
            | 'SL'
            | 'SG'
            | 'SX'
            | 'SK'
            | 'SI'
            | 'SB'
            | 'SO'
            | 'ZA'
            | 'GS'
            | 'SS'
            | 'ES'
            | 'LK'
            | 'SD'
            | 'SR'
            | 'SJ'
            | 'SZ'
            | 'SE'
            | 'CH'
            | 'SY'
            | 'TW'
            | 'TJ'
            | 'TZ'
            | 'TH'
            | 'TL'
            | 'TG'
            | 'TK'
            | 'TO'
            | 'TT'
            | 'TN'
            | 'TR'
            | 'TM'
            | 'TC'
            | 'TV'
            | 'UG'
            | 'UA'
            | 'AE'
            | 'GB'
            | 'US'
            | 'UM'
            | 'UY'
            | 'UZ'
            | 'VU'
            | 'VE'
            | 'VN'
            | 'VG'
            | 'VI'
            | 'WF'
            | 'EH'
            | 'YE'
            | 'ZM'
            | 'ZW';
          postalCode?: string;
          state?: string;
          street1?: string;
          street2?: string;
        };
      };
      nextTransactionNumberSeed?: string;
      prospect?: {
        id: number;
      };
      threatMetrixSessionUuid?: string;
    }) => {
      return this.client.post(
        `/ip_contributions/actions/send_payment`,
        payload
      );
    },
  };

  // IPOnboarding
  iPOnboarding = {
    // get /ip_onboarding
    info: () => {
      return this.client.get<schemas.IPOnboarding>(`/ip_onboarding`);
    },
  };

  // Login
  login = {
    // delete /login
    destroy: () => {
      return this.client.delete(`/login`);
    },

    // post /login/actions/choose_password
    choosePassword: (payload: {
      password?: string;
      resetToken?: string;
      username?: string;
    }) => {
      return this.client.post<schemas.SessionInfo>(
        `/login/actions/choose_password`,
        payload
      );
    },

    // post /login/actions/forgot_password
    forgotPassword: (payload: { email?: string }) => {
      return this.client.post(`/login/actions/forgot_password`, payload);
    },

    // post /login/actions/login_with_mfa
    loginWithMfa: (payload: {
      email?: string;
      isNewContact?: boolean;
      mfaMethod?: string;
      password: string;
      rememberMe?: boolean;
      token?: string;
    }) => {
      return this.client.post<schemas.SessionInfo>(
        `/login/actions/login_with_mfa`,
        payload
      );
    },

    // post /login/actions/refresh_access_token
    refreshAccessToken: (payload: {
      clientId?: string;
      clientSecret?: string;
      grantType?: string;
      refreshToken?: string;
    }) => {
      return this.client.post<schemas.SessionInfo>(
        `/login/actions/refresh_access_token`,
        payload
      );
    },

    // post /login/actions/reset_password
    resetPassword: (payload: {
      password?: string;
      resetToken?: string;
      username?: string;
    }) => {
      return this.client.post(`/login/actions/reset_password`, payload);
    },

    // post /login/actions/verify_activated
    verifyActivated: (payload: { username?: string }) => {
      return this.client.post(`/login/actions/verify_activated`, payload);
    },

    // post /login/actions/verify_invitation
    verifyInvitation: (payload: { resetToken?: string; username?: string }) => {
      return this.client.post(`/login/actions/verify_invitation`, payload);
    },

    // post /login/password
    password: (payload: {
      email?: string;
      password?: string;
      remember2faDeviceToken?: string;
      sendMfaCode?: boolean;
    }) => {
      return this.client.post<schemas.SessionInfo>(`/login/password`, payload);
    },

    // post /login/show
    show: (payload: { token?: string }) => {
      return this.client.post<schemas.SessionInfo>(`/login/show`, payload);
    },
  };

  // Mfa
  mfa = {
    // post /mfa/actions/register
    register: (payload: {
      countryCode?: string;
      mfaMethod?: 'sms' | 'call';
      phoneNumber?: string;
    }) => {
      return this.client.post(`/mfa/actions/register`, payload);
    },

    // post /mfa/actions/register_totp
    registerTotp: () => {
      return this.client.post<schemas.MfaTotp>(`/mfa/actions/register_totp`);
    },

    // post /mfa/actions/register_verify
    registerVerify: (payload: {
      mfaMethod?: 'sms' | 'call' | 'totp';
      token?: string;
    }) => {
      return this.client.post(`/mfa/actions/register_verify`, payload);
    },

    // post /mfa/actions/request_token
    requestToken: (payload: { mfaMethod?: 'sms' | 'call' }) => {
      return this.client.post(`/mfa/actions/request_token`, payload);
    },
  };

  // NoAuthPMProfiles
  noAuthPMProfiles = {
    // post /no_auth_pm_profiles
    create: (payload: {
      accessToken?: string;
      expiresIn?: number;
      originalReferrer?: string;
      refreshExpiresIn?: number;
      refreshToken?: string;
      tokenType?: string;
    }) => {
      return this.client.post(`/no_auth_pm_profiles`, payload);
    },
  };

  // Opportunities
  opportunities = {
    // get /opportunities
    index: (params: { fields?: string; view?: string } = {}) => {
      return this.client.get<schemas.Opportunity[]>(
        `/opportunities?${stringify(params)}`
      );
    },

    // get /opportunities/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.Opportunity>(
        `/opportunities/${params.id}?${stringify(params)}`
      );
    },

    // post /opportunities/{id}/actions/submit_interest
    submitInterest: (
      params: { id: number },
      payload: {
        contact?: {
          dob?: Date;
          maritalStatus?: 'single' | 'married';
        };
        desiredCommitment?: number;
        holdingTitle?:
          | 'individual'
          | 'joint_tenants'
          | 'tenants_in_common'
          | 'tenants_by_entirety'
          | 'community_property';
        investor?: {
          id: number;
          updatePayload?: {
            beneficialOwnerName?: string;
            beneficialOwnerTin?: string;
            disregardedEntity?: boolean;
            entityType?:
              | 'individual'
              | 'corporation'
              | 'estate_or_trust'
              | 'partnership'
              | 'exempt_organization'
              | 'ira'
              | 'foreign_government';
            noUsTin?: boolean;
            taxAddress?: {
              city?: string;
              countryCode?:
                | 'AF'
                | 'AX'
                | 'AL'
                | 'DZ'
                | 'AS'
                | 'AD'
                | 'AO'
                | 'AI'
                | 'AQ'
                | 'AG'
                | 'AR'
                | 'AM'
                | 'AW'
                | 'AU'
                | 'AT'
                | 'AZ'
                | 'BS'
                | 'BH'
                | 'BD'
                | 'BB'
                | 'BY'
                | 'BE'
                | 'BZ'
                | 'BJ'
                | 'BM'
                | 'BT'
                | 'BO'
                | 'BQ'
                | 'BA'
                | 'BW'
                | 'BV'
                | 'BR'
                | 'IO'
                | 'BN'
                | 'BG'
                | 'BF'
                | 'BI'
                | 'CV'
                | 'KH'
                | 'CM'
                | 'CA'
                | 'KY'
                | 'CF'
                | 'TD'
                | 'CL'
                | 'CN'
                | 'CX'
                | 'CC'
                | 'CO'
                | 'KM'
                | 'CG'
                | 'CD'
                | 'CK'
                | 'CR'
                | 'CI'
                | 'HR'
                | 'CU'
                | 'CW'
                | 'CY'
                | 'CZ'
                | 'DK'
                | 'DJ'
                | 'DM'
                | 'DO'
                | 'EC'
                | 'EG'
                | 'SV'
                | 'GQ'
                | 'ER'
                | 'EE'
                | 'ET'
                | 'FK'
                | 'FO'
                | 'FJ'
                | 'FI'
                | 'FR'
                | 'GF'
                | 'PF'
                | 'TF'
                | 'GA'
                | 'GM'
                | 'GE'
                | 'DE'
                | 'GH'
                | 'GI'
                | 'GR'
                | 'GL'
                | 'GD'
                | 'GP'
                | 'GU'
                | 'GT'
                | 'GG'
                | 'GN'
                | 'GW'
                | 'GY'
                | 'HT'
                | 'HM'
                | 'VA'
                | 'HN'
                | 'HK'
                | 'HU'
                | 'IS'
                | 'IN'
                | 'ID'
                | 'IR'
                | 'IQ'
                | 'IE'
                | 'IM'
                | 'IL'
                | 'IT'
                | 'JM'
                | 'JP'
                | 'JE'
                | 'JO'
                | 'KZ'
                | 'KE'
                | 'KI'
                | 'KP'
                | 'KR'
                | 'KW'
                | 'KG'
                | 'LA'
                | 'LV'
                | 'LB'
                | 'LS'
                | 'LR'
                | 'LY'
                | 'LI'
                | 'LT'
                | 'LU'
                | 'MO'
                | 'MK'
                | 'MG'
                | 'MW'
                | 'MY'
                | 'MV'
                | 'ML'
                | 'MT'
                | 'MH'
                | 'MQ'
                | 'MR'
                | 'MU'
                | 'YT'
                | 'MX'
                | 'FM'
                | 'MD'
                | 'MC'
                | 'MN'
                | 'ME'
                | 'MS'
                | 'MA'
                | 'MZ'
                | 'MM'
                | 'NA'
                | 'NR'
                | 'NP'
                | 'NL'
                | 'NC'
                | 'NZ'
                | 'NI'
                | 'NE'
                | 'NG'
                | 'NU'
                | 'NF'
                | 'MP'
                | 'NO'
                | 'OM'
                | 'PK'
                | 'PW'
                | 'PS'
                | 'PA'
                | 'PG'
                | 'PY'
                | 'PE'
                | 'PH'
                | 'PN'
                | 'PL'
                | 'PT'
                | 'PR'
                | 'QA'
                | 'RE'
                | 'RO'
                | 'RU'
                | 'RW'
                | 'BL'
                | 'SH'
                | 'KN'
                | 'LC'
                | 'MF'
                | 'PM'
                | 'VC'
                | 'WS'
                | 'SM'
                | 'ST'
                | 'SA'
                | 'SN'
                | 'RS'
                | 'SC'
                | 'SL'
                | 'SG'
                | 'SX'
                | 'SK'
                | 'SI'
                | 'SB'
                | 'SO'
                | 'ZA'
                | 'GS'
                | 'SS'
                | 'ES'
                | 'LK'
                | 'SD'
                | 'SR'
                | 'SJ'
                | 'SZ'
                | 'SE'
                | 'CH'
                | 'SY'
                | 'TW'
                | 'TJ'
                | 'TZ'
                | 'TH'
                | 'TL'
                | 'TG'
                | 'TK'
                | 'TO'
                | 'TT'
                | 'TN'
                | 'TR'
                | 'TM'
                | 'TC'
                | 'TV'
                | 'UG'
                | 'UA'
                | 'AE'
                | 'GB'
                | 'US'
                | 'UM'
                | 'UY'
                | 'UZ'
                | 'VU'
                | 'VE'
                | 'VN'
                | 'VG'
                | 'VI'
                | 'WF'
                | 'EH'
                | 'YE'
                | 'ZM'
                | 'ZW';
              pobox?: string;
              postalCode?: string;
              state?: string;
              street1?: string;
              street2?: string;
            };
            tin?: string;
          };
        };
        lpOpportunityComments?: string;
        newInvestor?: {
          beneficialOwnerName?: string;
          beneficialOwnerTin?: string;
          disregardedEntity?: boolean;
          entityType?:
            | 'individual'
            | 'corporation'
            | 'estate_or_trust'
            | 'partnership'
            | 'exempt_organization'
            | 'ira'
            | 'foreign_government';
          name?: string;
          noUsTin?: boolean;
          taxAddress?: {
            city?: string;
            countryCode?:
              | 'AF'
              | 'AX'
              | 'AL'
              | 'DZ'
              | 'AS'
              | 'AD'
              | 'AO'
              | 'AI'
              | 'AQ'
              | 'AG'
              | 'AR'
              | 'AM'
              | 'AW'
              | 'AU'
              | 'AT'
              | 'AZ'
              | 'BS'
              | 'BH'
              | 'BD'
              | 'BB'
              | 'BY'
              | 'BE'
              | 'BZ'
              | 'BJ'
              | 'BM'
              | 'BT'
              | 'BO'
              | 'BQ'
              | 'BA'
              | 'BW'
              | 'BV'
              | 'BR'
              | 'IO'
              | 'BN'
              | 'BG'
              | 'BF'
              | 'BI'
              | 'CV'
              | 'KH'
              | 'CM'
              | 'CA'
              | 'KY'
              | 'CF'
              | 'TD'
              | 'CL'
              | 'CN'
              | 'CX'
              | 'CC'
              | 'CO'
              | 'KM'
              | 'CG'
              | 'CD'
              | 'CK'
              | 'CR'
              | 'CI'
              | 'HR'
              | 'CU'
              | 'CW'
              | 'CY'
              | 'CZ'
              | 'DK'
              | 'DJ'
              | 'DM'
              | 'DO'
              | 'EC'
              | 'EG'
              | 'SV'
              | 'GQ'
              | 'ER'
              | 'EE'
              | 'ET'
              | 'FK'
              | 'FO'
              | 'FJ'
              | 'FI'
              | 'FR'
              | 'GF'
              | 'PF'
              | 'TF'
              | 'GA'
              | 'GM'
              | 'GE'
              | 'DE'
              | 'GH'
              | 'GI'
              | 'GR'
              | 'GL'
              | 'GD'
              | 'GP'
              | 'GU'
              | 'GT'
              | 'GG'
              | 'GN'
              | 'GW'
              | 'GY'
              | 'HT'
              | 'HM'
              | 'VA'
              | 'HN'
              | 'HK'
              | 'HU'
              | 'IS'
              | 'IN'
              | 'ID'
              | 'IR'
              | 'IQ'
              | 'IE'
              | 'IM'
              | 'IL'
              | 'IT'
              | 'JM'
              | 'JP'
              | 'JE'
              | 'JO'
              | 'KZ'
              | 'KE'
              | 'KI'
              | 'KP'
              | 'KR'
              | 'KW'
              | 'KG'
              | 'LA'
              | 'LV'
              | 'LB'
              | 'LS'
              | 'LR'
              | 'LY'
              | 'LI'
              | 'LT'
              | 'LU'
              | 'MO'
              | 'MK'
              | 'MG'
              | 'MW'
              | 'MY'
              | 'MV'
              | 'ML'
              | 'MT'
              | 'MH'
              | 'MQ'
              | 'MR'
              | 'MU'
              | 'YT'
              | 'MX'
              | 'FM'
              | 'MD'
              | 'MC'
              | 'MN'
              | 'ME'
              | 'MS'
              | 'MA'
              | 'MZ'
              | 'MM'
              | 'NA'
              | 'NR'
              | 'NP'
              | 'NL'
              | 'NC'
              | 'NZ'
              | 'NI'
              | 'NE'
              | 'NG'
              | 'NU'
              | 'NF'
              | 'MP'
              | 'NO'
              | 'OM'
              | 'PK'
              | 'PW'
              | 'PS'
              | 'PA'
              | 'PG'
              | 'PY'
              | 'PE'
              | 'PH'
              | 'PN'
              | 'PL'
              | 'PT'
              | 'PR'
              | 'QA'
              | 'RE'
              | 'RO'
              | 'RU'
              | 'RW'
              | 'BL'
              | 'SH'
              | 'KN'
              | 'LC'
              | 'MF'
              | 'PM'
              | 'VC'
              | 'WS'
              | 'SM'
              | 'ST'
              | 'SA'
              | 'SN'
              | 'RS'
              | 'SC'
              | 'SL'
              | 'SG'
              | 'SX'
              | 'SK'
              | 'SI'
              | 'SB'
              | 'SO'
              | 'ZA'
              | 'GS'
              | 'SS'
              | 'ES'
              | 'LK'
              | 'SD'
              | 'SR'
              | 'SJ'
              | 'SZ'
              | 'SE'
              | 'CH'
              | 'SY'
              | 'TW'
              | 'TJ'
              | 'TZ'
              | 'TH'
              | 'TL'
              | 'TG'
              | 'TK'
              | 'TO'
              | 'TT'
              | 'TN'
              | 'TR'
              | 'TM'
              | 'TC'
              | 'TV'
              | 'UG'
              | 'UA'
              | 'AE'
              | 'GB'
              | 'US'
              | 'UM'
              | 'UY'
              | 'UZ'
              | 'VU'
              | 'VE'
              | 'VN'
              | 'VG'
              | 'VI'
              | 'WF'
              | 'EH'
              | 'YE'
              | 'ZM'
              | 'ZW';
            pobox?: string;
            postalCode?: string;
            state?: string;
            street1?: string;
            street2?: string;
          };
          tin?: string;
        };
        phoneNumber?: string;
        requireAccreditationForm?: boolean;
        updatingProspectId?: number;
      }
    ) => {
      return this.client.post(
        `/opportunities/${params.id}/actions/submit_interest`,
        payload
      );
    },

    // patch /opportunities/{id}/actions/update_offering_last_viewed_at
    updateOfferingLastViewedAt: (params: { id: number }) => {
      return this.client.patch(
        `/opportunities/${params.id}/actions/update_offering_last_viewed_at`
      );
    },
  };

  // OpportunityAssets
  opportunityAssets = {
    // get /opportunity_assets/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.OpportunityAsset>(
        `/opportunity_assets/${params.id}?${stringify(params)}`
      );
    },
  };

  // PerformanceMeasurements
  performanceMeasurements = {
    // get /performance_measurements/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.PerformanceMeasurement>(
        `/performance_measurements/${params.id}?${stringify(params)}`
      );
    },
  };

  // Plaid
  plaid = {
    // post /plaid/actions/create_plaid_link_token_v2
    createPlaidLinkTokenV2: (payload: {
      bankAccount?: {
        id?: number;
      };
      sameDayMicroDepositVerifyFlow?: boolean;
    }) => {
      return this.client.post<schemas.IPPlaidLinkToken>(
        `/plaid/actions/create_plaid_link_token_v2`,
        payload
      );
    },
  };

  // PMProfiles
  pMProfiles = {
    // post /pm_profiles
    create: (payload: {
      accessToken?: string;
      accreditationVerificationId?: number;
      expiresIn?: number;
      refreshExpiresIn?: number;
      refreshToken?: string;
      tokenType?: string;
    }) => {
      return this.client.post(`/pm_profiles`, payload);
    },

    // get /pm_profiles/{id}
    show: (params: { id: number; fields?: string }) => {
      return this.client.get<schemas.PMProfile>(
        `/pm_profiles/${params.id}?${stringify(params)}`
      );
    },
  };

  // Positions
  positions = {
    // get /positions
    index: (
      params: {
        fields?: string;
        filters?: string;
        pagination?: string;
        view?: string;
      } = {}
    ) => {
      return this.client.get<schemas.Position[]>(
        `/positions?${stringify(params)}`
      );
    },

    // patch /positions/actions/bulk_update_reinvest
    bulkUpdateReinvest: (
      payload: {
        position?: {
          id: number;
        };
        reinvest?: boolean;
      }[]
    ) => {
      return this.client.patch(
        `/positions/actions/bulk_update_reinvest`,
        payload
      );
    },

    // get /positions/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.Position>(
        `/positions/${params.id}?${stringify(params)}`
      );
    },
  };

  // ProjectUpdates
  projectUpdates = {
    // get /project_updates
    index: (
      params: { fields?: string; filters?: string; view?: string } = {}
    ) => {
      return this.client.get<schemas.ProjectUpdate[]>(
        `/project_updates?${stringify(params)}`
      );
    },
  };

  // ProspectContributions
  prospectContributions = {
    // get /prospect_contributions
    index: (params: { fields?: string; view?: string } = {}) => {
      return this.client.get<schemas.ProspectContribution[]>(
        `/prospect_contributions?${stringify(params)}`
      );
    },

    // get /prospect_contributions/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.ProspectContribution>(
        `/prospect_contributions/${params.id}?${stringify(params)}`
      );
    },
  };

  // Prospects
  prospects = {
    // get /prospects
    index: (params: { fields?: string; view?: string } = {}) => {
      return this.client.get<schemas.Prospect[]>(
        `/prospects?${stringify(params)}`
      );
    },

    // get /prospects/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.Prospect>(
        `/prospects/${params.id}?${stringify(params)}`
      );
    },

    // post /prospects/{id}/actions/confirm_entity
    confirmEntity: (
      params: { id: number },
      payload: {
        contacts?: {
          email?: string;
          firstName?: string;
          id?: number;
          lastName?: string;
          phone?: string;
        }[];
        desiredCommitment?: number;
        holdingTitle?:
          | 'individual'
          | 'joint_tenants'
          | 'tenants_in_common'
          | 'tenants_by_entirety'
          | 'community_property';
        investor?: {
          id: number;
          updatePayload?: {
            beneficialOwnerName?: string;
            beneficialOwnerTin?: string;
            disregardedEntity?: boolean;
            entityType?:
              | 'individual'
              | 'corporation'
              | 'estate_or_trust'
              | 'partnership'
              | 'exempt_organization'
              | 'ira'
              | 'foreign_government';
            noUsTin?: boolean;
            taxAddress?: {
              city?: string;
              countryCode?:
                | 'AF'
                | 'AX'
                | 'AL'
                | 'DZ'
                | 'AS'
                | 'AD'
                | 'AO'
                | 'AI'
                | 'AQ'
                | 'AG'
                | 'AR'
                | 'AM'
                | 'AW'
                | 'AU'
                | 'AT'
                | 'AZ'
                | 'BS'
                | 'BH'
                | 'BD'
                | 'BB'
                | 'BY'
                | 'BE'
                | 'BZ'
                | 'BJ'
                | 'BM'
                | 'BT'
                | 'BO'
                | 'BQ'
                | 'BA'
                | 'BW'
                | 'BV'
                | 'BR'
                | 'IO'
                | 'BN'
                | 'BG'
                | 'BF'
                | 'BI'
                | 'CV'
                | 'KH'
                | 'CM'
                | 'CA'
                | 'KY'
                | 'CF'
                | 'TD'
                | 'CL'
                | 'CN'
                | 'CX'
                | 'CC'
                | 'CO'
                | 'KM'
                | 'CG'
                | 'CD'
                | 'CK'
                | 'CR'
                | 'CI'
                | 'HR'
                | 'CU'
                | 'CW'
                | 'CY'
                | 'CZ'
                | 'DK'
                | 'DJ'
                | 'DM'
                | 'DO'
                | 'EC'
                | 'EG'
                | 'SV'
                | 'GQ'
                | 'ER'
                | 'EE'
                | 'ET'
                | 'FK'
                | 'FO'
                | 'FJ'
                | 'FI'
                | 'FR'
                | 'GF'
                | 'PF'
                | 'TF'
                | 'GA'
                | 'GM'
                | 'GE'
                | 'DE'
                | 'GH'
                | 'GI'
                | 'GR'
                | 'GL'
                | 'GD'
                | 'GP'
                | 'GU'
                | 'GT'
                | 'GG'
                | 'GN'
                | 'GW'
                | 'GY'
                | 'HT'
                | 'HM'
                | 'VA'
                | 'HN'
                | 'HK'
                | 'HU'
                | 'IS'
                | 'IN'
                | 'ID'
                | 'IR'
                | 'IQ'
                | 'IE'
                | 'IM'
                | 'IL'
                | 'IT'
                | 'JM'
                | 'JP'
                | 'JE'
                | 'JO'
                | 'KZ'
                | 'KE'
                | 'KI'
                | 'KP'
                | 'KR'
                | 'KW'
                | 'KG'
                | 'LA'
                | 'LV'
                | 'LB'
                | 'LS'
                | 'LR'
                | 'LY'
                | 'LI'
                | 'LT'
                | 'LU'
                | 'MO'
                | 'MK'
                | 'MG'
                | 'MW'
                | 'MY'
                | 'MV'
                | 'ML'
                | 'MT'
                | 'MH'
                | 'MQ'
                | 'MR'
                | 'MU'
                | 'YT'
                | 'MX'
                | 'FM'
                | 'MD'
                | 'MC'
                | 'MN'
                | 'ME'
                | 'MS'
                | 'MA'
                | 'MZ'
                | 'MM'
                | 'NA'
                | 'NR'
                | 'NP'
                | 'NL'
                | 'NC'
                | 'NZ'
                | 'NI'
                | 'NE'
                | 'NG'
                | 'NU'
                | 'NF'
                | 'MP'
                | 'NO'
                | 'OM'
                | 'PK'
                | 'PW'
                | 'PS'
                | 'PA'
                | 'PG'
                | 'PY'
                | 'PE'
                | 'PH'
                | 'PN'
                | 'PL'
                | 'PT'
                | 'PR'
                | 'QA'
                | 'RE'
                | 'RO'
                | 'RU'
                | 'RW'
                | 'BL'
                | 'SH'
                | 'KN'
                | 'LC'
                | 'MF'
                | 'PM'
                | 'VC'
                | 'WS'
                | 'SM'
                | 'ST'
                | 'SA'
                | 'SN'
                | 'RS'
                | 'SC'
                | 'SL'
                | 'SG'
                | 'SX'
                | 'SK'
                | 'SI'
                | 'SB'
                | 'SO'
                | 'ZA'
                | 'GS'
                | 'SS'
                | 'ES'
                | 'LK'
                | 'SD'
                | 'SR'
                | 'SJ'
                | 'SZ'
                | 'SE'
                | 'CH'
                | 'SY'
                | 'TW'
                | 'TJ'
                | 'TZ'
                | 'TH'
                | 'TL'
                | 'TG'
                | 'TK'
                | 'TO'
                | 'TT'
                | 'TN'
                | 'TR'
                | 'TM'
                | 'TC'
                | 'TV'
                | 'UG'
                | 'UA'
                | 'AE'
                | 'GB'
                | 'US'
                | 'UM'
                | 'UY'
                | 'UZ'
                | 'VU'
                | 'VE'
                | 'VN'
                | 'VG'
                | 'VI'
                | 'WF'
                | 'EH'
                | 'YE'
                | 'ZM'
                | 'ZW';
              pobox?: string;
              postalCode?: string;
              state?: string;
              street1?: string;
              street2?: string;
            };
            tin?: string;
          };
        };
        newInvestor?: {
          beneficialOwnerName?: string;
          beneficialOwnerTin?: string;
          disregardedEntity?: boolean;
          entityType?:
            | 'individual'
            | 'corporation'
            | 'estate_or_trust'
            | 'partnership'
            | 'exempt_organization'
            | 'ira'
            | 'foreign_government';
          name?: string;
          noUsTin?: boolean;
          taxAddress?: {
            city?: string;
            countryCode?:
              | 'AF'
              | 'AX'
              | 'AL'
              | 'DZ'
              | 'AS'
              | 'AD'
              | 'AO'
              | 'AI'
              | 'AQ'
              | 'AG'
              | 'AR'
              | 'AM'
              | 'AW'
              | 'AU'
              | 'AT'
              | 'AZ'
              | 'BS'
              | 'BH'
              | 'BD'
              | 'BB'
              | 'BY'
              | 'BE'
              | 'BZ'
              | 'BJ'
              | 'BM'
              | 'BT'
              | 'BO'
              | 'BQ'
              | 'BA'
              | 'BW'
              | 'BV'
              | 'BR'
              | 'IO'
              | 'BN'
              | 'BG'
              | 'BF'
              | 'BI'
              | 'CV'
              | 'KH'
              | 'CM'
              | 'CA'
              | 'KY'
              | 'CF'
              | 'TD'
              | 'CL'
              | 'CN'
              | 'CX'
              | 'CC'
              | 'CO'
              | 'KM'
              | 'CG'
              | 'CD'
              | 'CK'
              | 'CR'
              | 'CI'
              | 'HR'
              | 'CU'
              | 'CW'
              | 'CY'
              | 'CZ'
              | 'DK'
              | 'DJ'
              | 'DM'
              | 'DO'
              | 'EC'
              | 'EG'
              | 'SV'
              | 'GQ'
              | 'ER'
              | 'EE'
              | 'ET'
              | 'FK'
              | 'FO'
              | 'FJ'
              | 'FI'
              | 'FR'
              | 'GF'
              | 'PF'
              | 'TF'
              | 'GA'
              | 'GM'
              | 'GE'
              | 'DE'
              | 'GH'
              | 'GI'
              | 'GR'
              | 'GL'
              | 'GD'
              | 'GP'
              | 'GU'
              | 'GT'
              | 'GG'
              | 'GN'
              | 'GW'
              | 'GY'
              | 'HT'
              | 'HM'
              | 'VA'
              | 'HN'
              | 'HK'
              | 'HU'
              | 'IS'
              | 'IN'
              | 'ID'
              | 'IR'
              | 'IQ'
              | 'IE'
              | 'IM'
              | 'IL'
              | 'IT'
              | 'JM'
              | 'JP'
              | 'JE'
              | 'JO'
              | 'KZ'
              | 'KE'
              | 'KI'
              | 'KP'
              | 'KR'
              | 'KW'
              | 'KG'
              | 'LA'
              | 'LV'
              | 'LB'
              | 'LS'
              | 'LR'
              | 'LY'
              | 'LI'
              | 'LT'
              | 'LU'
              | 'MO'
              | 'MK'
              | 'MG'
              | 'MW'
              | 'MY'
              | 'MV'
              | 'ML'
              | 'MT'
              | 'MH'
              | 'MQ'
              | 'MR'
              | 'MU'
              | 'YT'
              | 'MX'
              | 'FM'
              | 'MD'
              | 'MC'
              | 'MN'
              | 'ME'
              | 'MS'
              | 'MA'
              | 'MZ'
              | 'MM'
              | 'NA'
              | 'NR'
              | 'NP'
              | 'NL'
              | 'NC'
              | 'NZ'
              | 'NI'
              | 'NE'
              | 'NG'
              | 'NU'
              | 'NF'
              | 'MP'
              | 'NO'
              | 'OM'
              | 'PK'
              | 'PW'
              | 'PS'
              | 'PA'
              | 'PG'
              | 'PY'
              | 'PE'
              | 'PH'
              | 'PN'
              | 'PL'
              | 'PT'
              | 'PR'
              | 'QA'
              | 'RE'
              | 'RO'
              | 'RU'
              | 'RW'
              | 'BL'
              | 'SH'
              | 'KN'
              | 'LC'
              | 'MF'
              | 'PM'
              | 'VC'
              | 'WS'
              | 'SM'
              | 'ST'
              | 'SA'
              | 'SN'
              | 'RS'
              | 'SC'
              | 'SL'
              | 'SG'
              | 'SX'
              | 'SK'
              | 'SI'
              | 'SB'
              | 'SO'
              | 'ZA'
              | 'GS'
              | 'SS'
              | 'ES'
              | 'LK'
              | 'SD'
              | 'SR'
              | 'SJ'
              | 'SZ'
              | 'SE'
              | 'CH'
              | 'SY'
              | 'TW'
              | 'TJ'
              | 'TZ'
              | 'TH'
              | 'TL'
              | 'TG'
              | 'TK'
              | 'TO'
              | 'TT'
              | 'TN'
              | 'TR'
              | 'TM'
              | 'TC'
              | 'TV'
              | 'UG'
              | 'UA'
              | 'AE'
              | 'GB'
              | 'US'
              | 'UM'
              | 'UY'
              | 'UZ'
              | 'VU'
              | 'VE'
              | 'VN'
              | 'VG'
              | 'VI'
              | 'WF'
              | 'EH'
              | 'YE'
              | 'ZM'
              | 'ZW';
            pobox?: string;
            postalCode?: string;
            state?: string;
            street1?: string;
            street2?: string;
          };
          tin?: string;
        };
        signatureRecipientId?: number;
      }
    ) => {
      return this.client.post(
        `/prospects/${params.id}/actions/confirm_entity`,
        payload
      );
    },

    // post /prospects/{id}/capital_sent
    capitalSent: (
      params: { id: number },
      payload: {
        details?: string;
      }
    ) => {
      return this.client.post(`/prospects/${params.id}/capital_sent`, payload);
    },
  };

  // PublicOpportunities
  publicOpportunities = {
    // get /public_opportunities
    index: (params: { fields?: string; pagination?: string } = {}) => {
      return this.client.get<schemas.PublicOpportunity[]>(
        `/public_opportunities?${stringify(params)}`
      );
    },

    // get /public_opportunities/{id}
    show: (params: { id: number; fields?: string }) => {
      return this.client.get<schemas.PublicOpportunity>(
        `/public_opportunities/${params.id}?${stringify(params)}`
      );
    },
  };

  // PublicSubmitInterestForm
  publicSubmitInterestForm = {
    // get /public_submit_interest_form/{id}
    show: (params: { id: number; fields?: string }) => {
      return this.client.get<schemas.PublicSubmitInterestForm>(
        `/public_submit_interest_form/${params.id}?${stringify(params)}`
      );
    },

    // post /public_submit_interest_form/{id}/actions/submit_interest
    submitInterest: (
      params: { id: number },
      payload: {
        desiredCommitment?: number;
        email?: string;
        firstName?: string;
        isAccredited?: boolean;
        lastName?: string;
        phoneNumber?: string;
      }
    ) => {
      return this.client.post(
        `/public_submit_interest_form/${params.id}/actions/submit_interest`,
        payload
      );
    },
  };

  // Questionnaires
  questionnaires = {
    // get /questionnaires
    index: (params: { fields?: string; view?: string } = {}) => {
      return this.client.get<schemas.Questionnaire[]>(
        `/questionnaires?${stringify(params)}`
      );
    },

    // get /questionnaires/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.Questionnaire>(
        `/questionnaires/${params.id}?${stringify(params)}`
      );
    },

    // post /questionnaires/{id}/actions/submit
    submit: (
      params: { id: number },
      payload: {
        documentKey?: string;
        imageKey?: string;
        signature?: string;
        title?: string;
      }
    ) => {
      return this.client.post(
        `/questionnaires/${params.id}/actions/submit`,
        payload
      );
    },
  };

  // RecentOfferings
  recentOfferings = {
    // get /recent_offerings
    index: (params: { fields?: string; view?: string } = {}) => {
      return this.client.get<schemas.RecentOffering[]>(
        `/recent_offerings?${stringify(params)}`
      );
    },

    // get /recent_offerings/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.RecentOffering>(
        `/recent_offerings/${params.id}?${stringify(params)}`
      );
    },
  };

  // Settings
  settings = {
    // get /settings
    index: (params: { fields?: string } = {}) => {
      return this.client.get<schemas.Settings>(
        `/settings?${stringify(params)}`
      );
    },
  };

  // SignatureRecipients
  signatureRecipients = {
    // get /signature_recipients
    index: (params: { fields?: string; view?: string } = {}) => {
      return this.client.get<schemas.SignatureRecipient[]>(
        `/signature_recipients?${stringify(params)}`
      );
    },
  };

  // SignatureSigners
  signatureSigners = {
    // get /signature_signers
    index: (params: { fields?: string; view?: string } = {}) => {
      return this.client.get<schemas.SignatureSigner[]>(
        `/signature_signers?${stringify(params)}`
      );
    },

    // get /signature_signers/{id}
    show: (params: { id: number; fields?: string; view?: string }) => {
      return this.client.get<schemas.SignatureSigner>(
        `/signature_signers/${params.id}?${stringify(params)}`
      );
    },

    // post /signature_signers/{id}/actions/execute_signature
    executeSignature: (
      params: { id: number },
      payload: {
        signature?: string;
        signatureFields?: {
          id: number;
          value?: string;
        }[];
        title?: string;
      }
    ) => {
      return this.client.post(
        `/signature_signers/${params.id}/actions/execute_signature`,
        payload
      );
    },

    // post /signature_signers/{id}/actions/save
    save: (
      params: { id: number },
      payload: {
        signatureFields?: {
          id: number;
          value?: string;
        }[];
      }
    ) => {
      return this.client.post(
        `/signature_signers/${params.id}/actions/save`,
        payload
      );
    },

    // post /signature_signers/{id}/actions/track_document_view
    trackDocumentView: (params: { id: number }) => {
      return this.client.post(
        `/signature_signers/${params.id}/actions/track_document_view`
      );
    },
  };

  // StatementBatches
  statementBatches = {
    // get /statement_batches/{id}
    show: (params: { id: number; fields?: string }) => {
      return this.client.get<schemas.StatementBatch>(
        `/statement_batches/${params.id}?${stringify(params)}`
      );
    },
  };

  // Trackers
  trackers = {
    // get /t/doc/{guid}
    document: (params: { guid: string; allow_redirect?: boolean }) => {
      return this.client.get(`/t/doc/${params.guid}?${stringify(params)}`);
    },
  };

  // Uploads
  uploads = {
    // post /uploads/actions/new_form
    newForm: () => {
      return this.client.post<schemas.UploadForm>(`/uploads/actions/new_form`);
    },
  };

  // Withholdings
  withholdings = {
    // get /withholdings
    index: (
      params: { fields?: string; filters?: string; view?: string } = {}
    ) => {
      return this.client.get<schemas.Withholding[]>(
        `/withholdings?${stringify(params)}`
      );
    },
  };
}
